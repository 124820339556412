import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TestimonialCardThree = ({ description, images, name, designation }) => {
    return (
        <div className="testimonial-wrap grid grid-cols-1 gap-10 md:grid-cols-[315px_minmax(0,_1fr)] xl:grid-cols-[441px_minmax(0,_1fr)]">
            <div className="testimonial-author-thumb">
                <GatsbyImage
                    image={getImage(images[0]?.src)}
                    alt={images[0]?.alt || name}
                />
            </div>
            <div className="testimonial-author">
                {description && (
                    <div className="testimonial-text mb-10">
                        <p className="text-[18px] md:text-[24px] xl:text-[36px] font-bold text-black leading-[1.5]">
                            {description}
                        </p>
                    </div>
                )}
                <div className="testimonial-author-content">
                    {name && (
                        <h5 className="testimonial-author-name text-[18px] leading-[1.444]">
                            {name}
                        </h5>
                    )}
                    {designation && (
                        <span className="testimonial-author-designation">
                            {designation}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

TestimonialCardThree.propTypes = {
    description: PropTypes.string,
    name: PropTypes.string,
    designation: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            alt: PropTypes.string,
        })
    ),
};

export default TestimonialCardThree;
