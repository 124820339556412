import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const TeamCard = ({ name, images, designation, contact_info, socials }) => {
    return (
        <div className="team">
            <a href="/" className="team-thumb">
                {images[0]?.src ? (
                    <GatsbyImage
                        className="w-full"
                        image={getImage(images[0]?.src)}
                        alt={images[0]?.alt || name}
                    />
                ) : (
                    <StaticImage
                        className="w-full"
                        src="../../data/images/servies/345x350.png"
                        alt={name}
                    />
                )}
            </a>
            <div className="team-content bg-white p-[25px_20px] xl:p-[25px_30px]">
                <h3 className="team-name leading-none">
                    <a href="/">{name}</a>
                </h3>
                <span className="team-designation">{designation}</span>
                <ul className="team-info mt-5">
                    {contact_info?.map((item) => (
                        <li
                            key={item.id}
                            className="mb-1 text-[14px] lg:text-base"
                        >
                            {item.title}:{" "}
                            {item.title === "Phone" ||
                            item.title === "phone" ? (
                                <a href="tel:${item.text}">{item.text}</a>
                            ) : (
                                <a href="mailto:${item.text}">{item.text}</a>
                            )}
                        </li>
                    ))}
                </ul>
                <ul className="team-social flex space-x-5 mt-5">
                    {socials?.map((item) => (
                        <li key={item.id} className="text-md">
                            <a
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className={item.icon}></i>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

TeamCard.propTypes = {
    name: PropTypes.string,
    designation: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            alt: PropTypes.string,
        })
    ),
    contact_info: PropTypes.array,
    socials: PropTypes.array,
};

export default TeamCard;
