import React from "react";
import SectionTitle from "../../components/section-title";
import PropTypes from "prop-types";
import TeamCard from "../../components/team-card";

const TeamSection = ({ data }) => {
    return (
        <section className="team-section bg-[#F3F3F9] section-space-ptb">
            <div className="container-full">
                {data?.section_title && (
                    <SectionTitle
                        heading={data?.section_title?.heading}
                        {...data.section_title}
                    />
                )}
                <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-7 mt-10">
                    {data?.items.map((item) => (
                        <TeamCard
                            key={item.id}
                            images={item.images}
                            name={item.name}
                            designation={item.designation}
                            contact_info={item.contact_info}
                            socials={item.socials}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

TeamSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default TeamSection;
