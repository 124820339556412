import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../components/section-title";
import InvestorCard from "../../components/investor-card";

const InvestorAndDevelopmentSection = ({ data }) => {
    return (
        <section className="investor-and-development-section section-space-ptb">
            <div className="container-full">
                <div className="grid grid-cols-1 lg:grid-cols-[315px_minmax(0,_1fr)] xl:grid-cols-[441px_minmax(0,_1fr)]">
                    {data?.section_title && (
                        <SectionTitle
                            heading={data?.section_title?.heading}
                            {...data.section_title}
                        />
                    )}
                    <div className="right-content">
                        <div className="content-block">
                            {data?.headings[0] && (
                                <h2 className="mb-10">
                                    {data?.headings[0]?.content}
                                </h2>
                            )}
                            {data?.texts &&
                                data?.texts.map((text) => (
                                    <p
                                        key={text.id}
                                        className="mb-5"
                                        dangerouslySetInnerHTML={{
                                            __html: text.content,
                                        }}
                                    />
                                ))}
                            {data?.items && (
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-10">
                                    {data?.items.map((item) => (
                                        <InvestorCard
                                            key={item.id}
                                            title={item.title}
                                            images={item.images}
                                            link={item.link}
                                            description={item.description}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

InvestorAndDevelopmentSection.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default InvestorAndDevelopmentSection;
