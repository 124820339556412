import React from "react";
import PropTypes from "prop-types";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

const InvestorCard = ({ title, images, link, description }) => {
    return (
        <div className="investor-card py-[30px] md:py-[50px] last:pb-0 border-t">
            <div className="investor-logo">
                <a href={link} target="_blank" rel="noreferrer">
                    {images[0]?.src ? (
                        <GatsbyImage
                            image={getImage(images[0]?.src)}
                            alt={images[0]?.alt || title}
                        />
                    ) : (
                        <StaticImage
                            className="w-full"
                            src="../../data/images/investor/140x50.png"
                            alt={title}
                        />
                    )}
                </a>
            </div>
            <div className="investor-content mt-4">
                <h5 className="investor-name text-md uppercase mb-[6px]">
                    {title}
                </h5>
                <a
                    className="investor-website text-[#0099FF]"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {link}
                </a>
                <p className="mt-5">{description}</p>
            </div>
        </div>
    );
};

InvestorCard.propTypes = {
    title: PropTypes.string,
    designation: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            alt: PropTypes.string,
        })
    ),
};

export default InvestorCard;
